import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEraser, faInfoCircle, faAngleRight, faFolder, faUserGraduate, faFileImage, faCheckCircle, faTimesCircle, faUserCog, faCog, faMinusCircle, faDownload } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigationaPathUpdated, enableDisableItem, ITEM_TYPE_CLASS, ITEM_TYPE_CONTAINER, ITEM_TYPE_PROBLEM, ROOT_PATH, ITEM_TYPE_STUDENT, navigate, cleanHomework, ITEM_TYPE_ROOT, NAVIGATION_TYPE_DIRECTION_IN } from '../lib/store/items';
import styled from "styled-components";
import {canEraseContainerSubmissions, getSelectedProblem, isProblemActable, isSubmissionAnswered, isProblemSeen, isTeacherUser, isManageContentView, isOperationalView, getCurrentNavigationContainerType, getcurrentContainerItemsType, getPathLastContainerItem} from '../lib/store/getters';
import {CursorPointerCss, UnselectableCss} from '../helper/mainStyledBlocks';
import Switch from "react-switch";
import Spinner from 'react-bootstrap/Spinner'
import { faCircle as faCircleSolid } from '@fortawesome/free-solid-svg-icons';
import { CORRECTION_STATUS_CORRECT, CORRECTION_STATUS_INCOMPLETE, CORRECTION_STATUS_INCORRECT, ITEM_STATUS_DISABLED, MANAGE_ACTION_TAB_CLASS_EDIT, MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT, backendHost } from '../lib/constants';
import { problemItemClicked } from '../lib/store/actionHelper';
import ItemsSortingWrapper from './itemsSortingWrapper';
import { showConfirmation } from "../lib/store/message";
import { addEditContainerViewShown, addEditClassViewShown, classStudentsManagementShown, cloneClass, removeClass, cloneContainer, removeContainer, removePeoblemFromContainer, downloadProblems } from '../lib/store/manage';
import { faClone, faEdit } from '@fortawesome/free-regular-svg-icons';
import { ActionCanfirmationDialog, ActionCloneDialog } from '../helper/helpers';

// import { getPathLastContainerItem } from "./getters";


const ProblemSubmissionInfoBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.5em;
    margin-top: 0.4em;
`;

const CorrectionFontAwesomeIcon = styled(FontAwesomeIcon) `{
    font-size: 1.6em;
    color: #cacaca;
    margin-right: 0.6em;
    
    &.correct {
        color: #78bf78;
    }
    
    &.incorrect {
        color: #ef4b4a;
    }

    &.incomplete {
        color: #ffbe00;
    }

}`;




const SubmissionStatus = styled.div`
    text-transform: capitalize;
`;


const ListItemIcon = styled(FontAwesomeIcon)`
    color: #7278ef;

    &.disabled {
        color: #666;
    }

    &.eraser {
        margin-right:10px;
        color: #d4cd70;
        font-size: 18px;
    }
`;

const NavigationItem = styled.div`
    ${UnselectableCss}
    ${CursorPointerCss}

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


    .eee {
        max-width: 80%;
        text-overflow: ellipsis;
        overflow:hidden;
    }
    
    .manage-actions {
        .remove {
            color: red;
        }
        .clone {
            color: green;
            margin-right: 10px;
        }

        .edit {
            color: orange;
            margin-right: 10px;
        }
        
    }

    padding: 1em 1em;
    &:not(:last-child) {
        border-bottom: 1px solid #272846;
    }
    font-weight: 500;
    font-size: 0.8em;
    min-height: 50px;
    color: #eadfdf;

    &.selected {
        background-color: #202133;
    }

    &.disabled {
        color: #666;
    }

    &.student {
        padding: 0em 0.1em 0em 1em !important;
        align-items: stretch;
        .st-row {
            display: flex;
            flex-direction: row;
            justify-content: start;
            flex-grow: 1;
            align-items: center;

            div {
                margin-right: 3px;
            }

            .bedge {
               align-self: flex-start; 
               min-width: 17px;
               margin-right: 0px;

               div {
                font-size: 9px;
                text-align: center;
                background-color: #7278ef;
                border-radius: 7px;
                min-width: 14px;
                margin-top: 5px;
               }
            }

            .score {
                margin-left: auto;
                font-size: 9px;
                color: #9e9d9d;
                padding-right: 3px;
            }

            .arrowR {
                margin-right: 0px !important;
            }
        }
    }

`;


const StyledNavigationActionBlockContainer = styled.div`
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    
    .manage {
        cursor: pointer;
    }

    .download {
        cursor: pointer;
        margin-left:10px;
    }
`;

const ItemEnableDisableSwitcher = ({items, item}) => {
    
    const {enableDisableAction, id, disabled} = item;
    const dispatch = useDispatch();
    let switcher = (<></>);

    if(enableDisableAction) {
        
        if(items.itemStatusChangingId === id) {
            switcher = (
                <div className="ty">
                    <Spinner className="ef" size="sm" animation="border" variant="primary" />
                </div>
            );
        } else {
            const enableDisableHandler = enableDisableAction ? ((checked, event, id)=> {
                event.stopPropagation();
                if(event.nativeEvent) event.nativeEvent.stopImmediatePropagation();
                dispatch(enableDisableItem(enableDisableAction, checked));
                return false;
            }) : false;

            switcher = (
                <div className="ac">
                    <Switch height={18} width={32} onChange={enableDisableHandler} checked={!disabled} />
                </div>
            );
        }
    }

    return switcher;
}


const EraseStudentContainerWorkButton = (dispatch, items, container, selectedStudentId) => {
    return (
        <ListItemIcon onClick = {
            () => {
                
                    dispatch(showConfirmation(
                    {
                        body: "Are you sure you want to celan student homework?",
                        onConfirmationAction: cleanHomework(container.id, selectedStudentId),
                    }));
            }
        } 
        className={"eraser"} icon={faEraser} />
    );
}


const ContainerNavigationItem = ({item, user}) => {
    const {id, score, badgeCount, name, enableDisableAction} = item;

    const isOpsView = isOperationalView(user);

    let onClickHandler = () => dispatch(navigate(item));

    const dispatch = useDispatch();
    const items = useSelector((state) => state.items);

    let switcher = (<></>);
    let bedgeClass = "ab";
    const hasScore = score !== undefined && score != null ;
    if(enableDisableAction) {
        switcher = (<ItemEnableDisableSwitcher key={16} items={items} 
            item={item} />);
    } else {
        if(hasScore) {
            bedgeClass = "ab cd";
        } else {
            bedgeClass = "ab cdd";
        }
    
    }

    let contentItemOtherBlock = (<></>);
    
    if(isOpsView) {
        contentItemOtherBlock = (
            <div key={'other'} className="aa">
                {
                    canEraseContainerSubmissions(user, items, item) ?
                        EraseStudentContainerWorkButton(dispatch, items, item, items.selectedStudentId) :
                        (<></>)
                }
                {switcher}
                {hasScore ? (<div key={11} className="ae">{score}</div>) : (<></>)}
                {badgeCount !== 0 ? (<div  key={12} className={bedgeClass}>{badgeCount}</div>) : (<></>)}
                <FontAwesomeIcon key={19} onClick={onClickHandler} icon={faAngleRight}/>
            </div>
        )
    } else {
        if(ITEM_TYPE_CLASS === item.type) {
            contentItemOtherBlock = (
                <div key="other" className="manage-actions">
                    <FontAwesomeIcon className="item edit" title="Edit" key={"edit"} 
                        onClick={() => { 

                            dispatch(addEditClassViewShown( 
                                {
                                    defaultTab: MANAGE_ACTION_TAB_CLASS_EDIT,
                                    otherData: { editClassId: item.id }
                                }
                            )) 

                         } } icon={faEdit}/>
                    <FontAwesomeIcon className="item clone" title="Clone" key={"clone"} 
                        onClick={() => { ActionCanfirmationDialog(dispatch, `Are you sure you want to clone "${item.name}"`, 
                            () => {dispatch(cloneClass(item.id))}) }} icon={faClone}/>
                    <FontAwesomeIcon className="item remove" title="Disable" key={"remove"} 
                            onClick={() => { ActionCanfirmationDialog(dispatch, `Are you sure you want to remove "${item.name}"`, 
                            () => {dispatch(removeClass(item.id))}) }}
                     icon={faMinusCircle}/>
                </div>
            )
        } else if(ITEM_TYPE_CONTAINER  === item.type) {
            contentItemOtherBlock = (
                <div key="other" className="manage-actions">
                    <FontAwesomeIcon className="item edit" title="Edit" key={"edit"} 
                        onClick={() => { 

                            dispatch(addEditContainerViewShown( 
                                {
                                    defaultTab: MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT,
                                    otherData: { selectedItem: item }
                                }
                            )) 

                         } } icon={faEdit}/>
                    <FontAwesomeIcon className="item clone" title="Clone" key={"clone"} 
                        onClick={() => { ActionCanfirmationDialog(dispatch, `Are you sure you want to clone "${item.name}"`, 
                            () => {dispatch(cloneContainer(items, item.id))}) }} icon={faClone}/>
					<FontAwesomeIcon className="item clone" title="Clone" key={"clone"}
                        onClick={() => { ActionCloneDialog(dispatch, `Are you sure you want to clone "${item.name}"`,
                            (parent,type) => {dispatch(cloneContainer(items, item.id, parent,type))}) }} icon={faClone}/>
                    <FontAwesomeIcon className="item remove" title="Disable" key={"remove"} 
                            onClick={() => { ActionCanfirmationDialog(dispatch, `Are you sure you want to remove "${item.name}"`, 
                            () => {dispatch(removeContainer(items, item.id))}) }}
                     icon={faMinusCircle}/>
                </div>
            )
        }
    }

    return (
        <NavigationItem key={id} >
            <div key={'name'} className="eee" title={id} onClick={onClickHandler}>
                <ListItemIcon icon={faFolder} /> {name}
            </div>
            {contentItemOtherBlock}
 
        </NavigationItem>
    );
}

const StudentNavigationItem = ({item}) => {
    const dispatch = useDispatch();

    const {badgeCount, score} = item;

    let disabled = '';
    const className = ['student'];
    if(item.status === ITEM_STATUS_DISABLED) {
        disabled = '(disabled)';
        className.push('disabled');
    }


    const _navigate = () => item.status !== ITEM_STATUS_DISABLED ? dispatch(navigate(item)) : false;
    

    return (
        <NavigationItem className={className} onClick={ _navigate }>
            <div className={"st-row"}>
                <div>
                    <ListItemIcon key={"icon"} icon={faUserGraduate} /> 
                </div>
                <div key={"name"}>{item.name}{disabled}</div>
                <div key={"score"} className="score">{score}</div>
                <div className={"arrowR"}>
                    <FontAwesomeIcon key={"angleRight"} icon={faAngleRight}/>
                </div>
                <div key={"bedge"} className={"bedge"}>
                    {badgeCount !== 0 ? (<div>{badgeCount}</div>) : (<></>)}
                </div>
            </div>
        </NavigationItem>
    );
}



const ProblemNavigationItem = ({item}) => {

    const {id} = item;
    const dispatch = useDispatch();
    const items = useSelector((state) => state.items);
    const selectedProblem = getSelectedProblem(items);
    const user = useSelector((state) => state.user)

    let submissionInfoBlock;
    let scoreInfoBlock;

    const isOptView = isOperationalView(user);

    let problemItemAdditionalInfo = (<></>);

    let onClickHandler = null;

    // let contentItemOtherBlock = (<></>)
    
    if(isOptView) {
        if(isSubmissionAnswered(item)) {

            let correctionStatusIcon;
            const correctionStatus = item.submission.correctionStatus ? item.submission.correctionStatus : "submitted";

            switch(correctionStatus) {
                case CORRECTION_STATUS_CORRECT:
                    correctionStatusIcon = (<CorrectionFontAwesomeIcon className={correctionStatus} 
                        icon={faCheckCircle} />);
                    scoreInfoBlock = (<div className="ae">Score {item.submission.score}</div>);
                    break;
                case CORRECTION_STATUS_INCORRECT:
                    correctionStatusIcon = (<CorrectionFontAwesomeIcon className={correctionStatus} 
                        icon={faTimesCircle} />);
                    scoreInfoBlock = (<div className="ae">Score {item.submission.score}</div>);
                    break;
                case CORRECTION_STATUS_INCOMPLETE:
                    correctionStatusIcon = (<CorrectionFontAwesomeIcon className={correctionStatus} 
                        icon={faInfoCircle} />);
                    scoreInfoBlock = (<div className="ae">Score {item.submission.score}</div>);
                    break;
                default:
                    /**
                     * we treat this as submitted
                     */
                    correctionStatusIcon = (<CorrectionFontAwesomeIcon  className={correctionStatus}  
                        icon={faCircleSolid} />);
                    scoreInfoBlock = (<></>);
                    break;
            }

            submissionInfoBlock = (
                <>
                    <SubmissionStatus>{correctionStatusIcon}{correctionStatus}</SubmissionStatus>
                </>);

        } else if(isProblemActable(item)) {
            submissionInfoBlock = (
                <SubmissionStatus>
                    <CorrectionFontAwesomeIcon icon={faCircleSolid} />Unsolved
                </SubmissionStatus>);
            scoreInfoBlock = (<></>);
        }

        problemItemAdditionalInfo = (
            <>
                <div key={"seen"}>
                    {
                        !isProblemSeen(item) ? 
                            (<FontAwesomeIcon style={{color: '#7278ef'}} icon={faCircleSolid}/>) :
                            (<></>)
                    }
                </div>
                <div key={"score"}>{scoreInfoBlock}</div>
            </>
        )
        onClickHandler = () => problemItemClicked(dispatch, user, item)
    } else {
        onClickHandler = () => { 

            dispatch(navigationaPathUpdated({
                type: NAVIGATION_TYPE_DIRECTION_IN, //this is always direction in, can not be out
                item
            }));

            dispatch(addEditContainerViewShown( 
                {
                    defaultTab: MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT,
                    otherData: { selectedItem: item }
                }
            ));
        } 
    
        problemItemAdditionalInfo = (
            <div key="other" className="manage-actions">
                <FontAwesomeIcon className="item edit" title="Edit" key={"edit"} 
                    onClick={() => { 

                        dispatch(addEditContainerViewShown( 
                            {
                                defaultTab: MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT,
                                otherData: { selectedItem: item }
                            }
                        )) 

                        } } icon={faEdit}/>
                <FontAwesomeIcon className="item remove" title="Disable" key={"remove"} 
                        onClick={() => { ActionCanfirmationDialog(dispatch, `Are you sure you want to remove "${item.name}" from container`, 
                        () => {dispatch(removePeoblemFromContainer(items, item.id))}) }}
                    icon={faMinusCircle}/>
            </div>
        )
    }

    return (<>
        <NavigationItem key={id} 
                className={(selectedProblem.id === item.id ? 'selected' :'')} 
                onClick = { onClickHandler }>
            <div key={1} className="ra">
                <div key={3}>
                    <ListItemIcon icon={faFileImage} /> {item.name}
                </div>
                {isOptView ? (<ProblemSubmissionInfoBlock key={4}>{submissionInfoBlock}</ProblemSubmissionInfoBlock>) : (<></>)}
            </div>
            {problemItemAdditionalInfo}
        </NavigationItem>
    </>);
}


const NavigationBottomActionsBlock = (user, items) => {
    const dispatch = useDispatch();
    const navigationActions = [];


    const navCurrentContainerType = getCurrentNavigationContainerType(items);
    const navChildItemsType = getcurrentContainerItemsType(items);
    

    if(isManageContentView(user)) {
        if(ITEM_TYPE_ROOT === navCurrentContainerType) {
            navigationActions.push(
                (
                    <FontAwesomeIcon key={"icon"}
                        onClick={() => dispatch(addEditClassViewShown({}))}
                        className="manage" icon={faCog} />
                )
            )
        } else if(ITEM_TYPE_CONTAINER === navCurrentContainerType || ITEM_TYPE_CLASS === navCurrentContainerType) {
            navigationActions.push(
                (
                    
                    <FontAwesomeIcon key={"icon"}
                        onClick={() => dispatch(addEditContainerViewShown({}))}
                        className="manage" icon={faCog} />
                )
            )
            if(navChildItemsType === ITEM_TYPE_PROBLEM) {
                const _cntId = getPathLastContainerItem(items).id
                navigationActions.push(
                    (
                        <a href={`${backendHost}/webapp/manage/download-problems/${_cntId}`} target="_blank" download>
                            <FontAwesomeIcon key={"icon"}
                            onClick={() => {}}
                            className="download" icon={faDownload} />
                        </a>

                    )
                )
            }
        }
    } else {
        /**
         * show manage students button if in students block
         */
        if(isTeacherUser(user) && ITEM_TYPE_STUDENT === navChildItemsType && items.selectedClassId) {
            navigationActions.push(
                (
                    <FontAwesomeIcon key={"icon"}
                        onClick={() => dispatch(classStudentsManagementShown({}))}
                        className="manage" icon={faUserCog} />
                )
            );
        }
    }

    if(navigationActions) {
        return (
            <StyledNavigationActionBlockContainer>
                {navigationActions}
            </StyledNavigationActionBlockContainer>
        )
    } else {
        return (<></>);
    }
}



const NavigationBlock = (props) => {

    // const load

    const dispatch = useDispatch();
    const {items, user} = useSelector(state => state);
    
    useEffect(() => {
        dispatch(navigate(ROOT_PATH));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const {list} = items;

    if(items.loading) {
        return (
            <div className="col-3 class-navugation-block">
                <div className="cn-body">
                    <div className="cn-loading">Loading.....</div>
                </div>
            </div>
        );
    } else {

        /**
         * for every list we receive order value for every item
         * to show our list ordered. we can no rely on response order as the brower can change it
         * because it interpret the list as array not an object, as its keys are numerical
         */
        const listSortedItems = Object.values(list).sort(({name: aName}, {name: bName}) => aName.localeCompare(bName, 'en', { numeric: true }))

        
        return (
            <div className="col-3 class-navugation-block">
                <div className="cn-body">
                    <ItemsSortingWrapper>
                    {
                        listSortedItems.map((item) => {
                            switch(item.type) {
                                case ITEM_TYPE_CONTAINER:
                                case ITEM_TYPE_CLASS:
                                    return (
                                        <ContainerNavigationItem user={user} key={item.id} item={item}/>
                                    );
                                case ITEM_TYPE_PROBLEM:
                                    return (
                                        <ProblemNavigationItem user={user} key={item.id} item={item}/>
                                    );
                                case ITEM_TYPE_STUDENT:
                                    return (
                                        <StudentNavigationItem user={user} key={item.id} item={item}/>
                                    );
                                default:
                                    throw Error("invalid ITEM TYPE");
                            }
                        })
                    }
                    </ItemsSortingWrapper>
                    { NavigationBottomActionsBlock(user, items) }
                </div>
            </div>
        );
    }
}

export default NavigationBlock;