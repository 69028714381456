import multi from "multi.js/dist/multi-es6.min";
import { request } from "../lib/request";
import { showConfirmation, showClone } from "../lib/store/message";

export const loadClassAndSchoolStudents = (classStudentsSetter, schoolStudentsSetter, classId) => {
    request(`webapp/class-current-and-potential-attenders/${classId}`, 'POST', {}).then(response => {
        const { classStudents, schoolStudents } = response
        classStudentsSetter(classStudents);
        schoolStudentsSetter(schoolStudents);
    }).catch(error => {
        console.log("error loading school/class students")
    });
}


export const applyMultiOnClassStudents = (elementRef) => {
    multi(elementRef, {
        search_placeholder: "Search...",
        non_selected_header: "Available students",
        selected_header: "Attending students" 
    });
}

export const ActionCanfirmationDialog = (dispatch, text, onConfirm) => {
    dispatch(showConfirmation(
    {
        body: text,
        onConfirmationAction: onConfirm,
    }));
}

export const ActionCloneDialog = (dispatch, text, onConfirm) => {
    dispatch(showClone(
        {
            body: text,
            onConfirmationAction: onConfirm,
        }));
}